import { styled } from "@hoken/core/styles/stitches.config";

import { Content as RadixContent, Overlay as RadixOverlay } from "@radix-ui/react-dialog";
import { keyframes } from "@stitches/react";

const overlayAnimation = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const contentAnimation = keyframes({
  "0%": { opacity: 0, transform: "translate(-50%, -48%) scale(.96)" },
  "100%": { opacity: 1, transform: "translate(-50%, -50%) scale(1)" },
});

export const Overlay = styled(RadixOverlay, {
  backgroundColor: "$opacityGray",
  position: "fixed",
  inset: 0,
  animation: `${overlayAnimation} 500ms cubic-bezier(0.4, 0, 0.2, 1)`,
  zIndex: "3",
  "@reduceMotion": {
    animation: "none",
  },
});

export const Content = styled(RadixContent, {
  backgroundColor: "white",
  borderRadius: "$rounded",
  padding: "$4",
  width: "18.75rem",
  boxShadow:
    "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "3",
  "&:focus": { outline: "none" },
  "& .content-row": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  animation: `${contentAnimation} 500ms cubic-bezier(0.4, 0, 0.2, 1)`,
  "@reduceMotion": {
    animation: "none",
  },

  variants: {
    type: {
      bottom: {
        width: "100%",
        bottom: "calc(env(safe-area-inset-bottom))",
        transform: "translate(-50%, 0)",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        animation: "none",
        overflow: "auto",
        top: "34%",
      },
      error: {
        width: "100%",
        bottom: "calc(env(safe-area-inset-bottom))",
        transform: "translate(-50%, 0)",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        animation: "none",
        overflow: "auto",
        top: "initial",
        padding: "$hoken-core-space-24",
        backgroundColor: "$red20",
        display: "flex",
        flexDirection: "column",
        color: "$white",
        gap: "$4",
        p: {
          fontWeight: "$regular",
          fontSize: "$hoken-core-font-15",
          lineHeight: "$hoken-core-line-height-24",
          fontFamily: "$franklin",
        },
        // Error styles for trigger action component
        button: {
          alignSelf: "end",
          fontFamily: "$helvetica",
          fontWeight: "700",
          fontSize: "13px",
          lineHeight: "16px",
          letterSpacing: "0.05rem",
          textTransform: "uppercase",
        },
      },
    },
  },
});
