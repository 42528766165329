import { Times } from "./Icons/times/times.styles";
import { Content, Overlay } from "./dialog.styles";

import { Close, Portal, Root, Trigger } from "@radix-ui/react-dialog";
import type { CSS } from "@stitches/react";

export const DialogContent = ({
  children,
  type,
  onInteractOutside,
  onOpenAutoFocus,
  css = {},
}: {
  children: React.ReactNode;
  type?: "bottom" | "error";
  css?: CSS;
  // @ts-expect-error HTML event
  onInteractOutside?: (event) => void;
  onOpenAutoFocus?: () => void;
}) => {
  return (
    <Portal>
      <Overlay />
      <Content
        type={type}
        css={css}
        onInteractOutside={onInteractOutside}
        onOpenAutoFocus={onOpenAutoFocus}
      >
        {children}
      </Content>
    </Portal>
  );
};

export const DialogCrossClose = () => {
  return (
    <Close aria-label='Close'>
      <Times />
    </Close>
  );
};

export const Dialog = Root;
export const DialogTrigger = Trigger;
export const DialogClose = Close;
