/**
 * Returns a singular/plural word taking into account a count number
 * @example
 * pluralize(10, "room") // 10 rooms
 * @example
 * pluralize(2, "person", "people") // 2 people
 *
 */
export const pluralize = (
  count: number,
  word: string,
  plural: string = word + "s",
  includeCount = true,
) => {
  return (includeCount ? count + " " : "") + (count == 1 ? word : plural);
};
