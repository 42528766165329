import type React from "react";
import { HTMLAttributes } from "react";

import { buttonGroup, buttons } from "./buttons.vanilla.css";

import { classMerge } from "@hoken/design-system";

export interface ButtonProps extends HTMLAttributes<HTMLParagraphElement> {
  as?: keyof HTMLElementTagNameMap;
  type?:
    | "primary"
    | "secondary"
    | "pill"
    | "pill_dark"
    | "text"
    | "brand_primary_dark"
    | "tertiary"
    | "disabled";
  size?: "small" | "medium" | "large" | "xsmall";
  children: React.ReactNode;
  isLoading?: "text";
  width?: string;
}

export const Button = ({
  as = "button",
  type,
  size,
  isLoading,
  className,
  children,
  width,
  ...props
}: ButtonProps) => {
  const Component = as as any;
  const classNameButton = buttons({ type, size, isLoading, width });
  const sanitizedClassName = classMerge([classNameButton, className]);

  return (
    <Component className={`${sanitizedClassName}`} {...props}>
      {" "}
      {children}{" "}
    </Component>
  );
};

Button.Group = function ButtonGroup({ children }: { children: React.ReactNode }) {
  const className = buttonGroup({});
  return <div className={className}>{children}</div>;
};
