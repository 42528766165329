export const formatLocation = ({
  street,
  city,
  state,
}: {
  street?: string;
  city?: string;
  state?: string;
}) => {
  // only add ", " if both values are truthy
  return [street, city, state].filter(Boolean).join(", ");
};
