import { varsHoken } from "@hoken/design-system/themes";
import { keyframes } from "@vanilla-extract/css";
import { recipe } from "@vanilla-extract/recipes";

const ellipsis = keyframes({
  to: { width: "40px" },
});

export const buttons = recipe({
  base: {
    // CSS Reset for button
    WebkitAppearance: "button",
    backgroundColor: "transparent",
    backgroundImage: "none",
    margin: 0,
    borderWidth: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem 1.5rem",
    gap: "0.5rem",
    width: "100%",
    borderRadius: "4px",
    fontFamily: varsHoken.fonts.family["helvetica"],
    fontWeight: varsHoken.fonts.weight["bold"],
    cursor: "pointer",
    textTransform: "uppercase",
  },

  variants: {
    type: {
      pill: {
        height: "auto",
        minHeight: "23px",
        padding: "6px 8px",
        fontSize: varsHoken.fonts.sizes["12"],
        lineHeight: varsHoken.fonts.sizes["12"],
        fontFamily: varsHoken.fonts.family.franklin,
        fontWeight: varsHoken.fonts.weight.regular,
        letterSpacing: "0.01em",
        backgroundColor: varsHoken.colors.white,
        boxShadow: "0px 3px 10px 0px #00000017",
        borderRadius: varsHoken.radius.full,
        border: `1px solid transparent`,
        width: "fit-content",
        textTransform: "none",

        ":hover": {
          border: `1px solid ${varsHoken.colors["gray600"]}`,
          backgroundColor: varsHoken.colors["gray500"],
        },

        ":active": {
          backgroundColor: varsHoken.colors["primary100"],
          border: `1px solid ${varsHoken.colors["primary"]}`,
          color: varsHoken.colors.primary,
        },

        selectors: {
          "&.active": {
            backgroundColor: varsHoken.colors["primary100"],
            border: `1px solid ${varsHoken.colors["primary"]}`,
            color: varsHoken.colors["primary"],
          },
        },
      },
      // @ts-expect-error vanilla has an issue with the getter property and throws but works as expect: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/get
      get pill_dark() {
        return {
          ...this.pill,
          color: varsHoken.colors.white,
          backgroundColor: varsHoken.colors.black,
          ":hover": {
            border: `1px solid ${varsHoken.colors.black}`,
            backgroundColor: "#333333",
          },

          ":active": {
            border: `1px solid ${varsHoken.colors.gray600}`,
            backgroundColor: varsHoken.colors.gray500,
            color: varsHoken.colors.black,
          },
          "& path": {
            fill: varsHoken.colors.white,
          },
        };
      },
      text: {
        color: varsHoken.colors["primary"],
        // Contents causes an element's children to appear as if they were direct children of the element's parent, ignoring the element itself
        display: "contents",

        // Button style reset
        flexDirection: "unset",
        justifyContent: "unset",
        alignItems: "unset",
        padding: "0",
        gap: "0",
        width: "100%",
        borderRadius: "unset",
        fontFamily: "unset",
        fontWeight: "unset",
        cursor: "pointer",
        textTransform: "unset",
      },
      primary: {
        color: varsHoken.colors["white"],
        textTransform: "uppercase",
        backgroundColor: varsHoken.colors["primary"],
        selectors: {
          "&:hover": {
            backgroundColor: "#3329A1",
          },
          "&:focus, &:focus-visible": {
            border: `2px solid ${varsHoken.colors["green100"]}`,
            outline: `${varsHoken.colors["green100"]} auto 2px`,
          },
        },
      },
      secondary: {
        color: varsHoken.colors["primary"],
        textTransform: "uppercase",
        backgroundColor: varsHoken.colors["white"],
        border: `2px solid ${varsHoken.colors["primary"]}`,
        selectors: {
          "&:hover": {
            backgroundColor: "#E5E2FF",
          },
          "&:focus, &:focus-visible": {
            backgroundColor: "#E5E2FF",
            border: `2px solid ${varsHoken.colors["primary"]}`,
            outline: `${varsHoken.colors["primary"]} auto 2px`,
          },
        },
      },
      tertiary: {
        color: varsHoken.colors["green100"],
        textTransform: "uppercase",
        backgroundColor: "transparent",
        border: `2px solid ${varsHoken.colors["green100"]}`,
      },
      brand_primary_dark: {
        color: varsHoken.colors["black"],
        textTransform: "uppercase",
        backgroundColor: varsHoken.colors["green100"],
        selectors: {
          "&:hover": {
            backgroundColor: varsHoken.colors["greenTint"],
          },
          "&:focus, &:focus-visible": {
            outline: `${varsHoken.colors["white"]} auto 2px`,
          },
        },
      },
      disabled: {
        pointerEvents: "none",
        backgroundColor: varsHoken.colors["gray600"],
        color: varsHoken.colors["gray700"],
        selectors: {
          "&:hover": {
            backgroundColor: varsHoken.colors["gray600"],
            color: varsHoken.colors["gray700"],
          },
          "&:focus": {
            border: varsHoken.colors["gray600"],
            outline: varsHoken.colors["gray600"],
          },
        },
      },
    },
    isLoading: {
      text: {
        color: varsHoken.colors.gray700,
        // typescript issue: https://github.com/mui/material-ui/issues/16307#issuecomment-1149081580
        pointerEvents: "none" as const,
        "::after": {
          // Visual state of a pending inline text link appends an ellipsis
          overflow: "hidden",
          display: "inline-block",
          verticalAlign: "bottom",
          animation: `${ellipsis} steps(4, end) 900ms infinite`,
          marginLeft: "-2px",
          content: ".",
          width: "0px",
        },
      },
    },
    size: {
      large: {
        height: "50px",
        fontSize: varsHoken.fonts.sizes["14"],
        lineHeight: "15px",
        letterSpacing: "0.05em",
      },
      medium: {
        height: "40px",
        fontSize: varsHoken.fonts.sizes["12"],
        letterSpacing: "0.05em",
      },
      small: {
        height: "30px",
        fontSize: varsHoken.fonts.sizes["10"],
        letterSpacing: "0.05em",
        whiteSpace: "nowrap",
        paddingTop: varsHoken.space["16"],
      },
      xsmall: {
        fontSize: varsHoken.fonts.sizes["10"],
        letterSpacing: "0.05em",
        height: "27px",
        padding: `${varsHoken.space[10]} ${varsHoken.space["12"]} ${varsHoken.space[8]}`,
      },
    },
    width: {
      fillContainer: {
        width: "100%",
      },
      hugContents: {
        width: "auto",
      },
    },
  },
  defaultVariants: {
    type: "primary",
    width: "auto",
  },
});

export const buttonGroup = recipe({
  base: {
    display: "flex",
    flexDirection: "row",
    gap: varsHoken.space["8"],
  },
});
