import { styled } from "@hoken/core/styles/stitches.config";

import type { CSS } from "@stitches/react";

export const header_h3: CSS = {
  fontFamily: "$helvetica",
  fontWeight: "$regular",
  fontSize: "$hoken-core-font-24",
  lineHeight: "$hoken-core-line-height-29",
};

export const header_h5: CSS = {
  fontFamily: "$helvetica",
  fontWeight: "$regular",
  fontSize: "$hoken-core-font-14",
  lineHeight: "$hoken-core-line-height-17",
};

export const Text = styled("p", {
  color: "$hoken-core-text-color",
  variants: {
    typography: {
      __UNIQUE__Header_84: {
        fontFamily: "$helvetica",
        fontWeight: "$regular",
        fontSize: "$hoken-core-font-84",
        lineHeight: "$hoken-core-line-height-100",
        letterSpacing: "-0.005rem",
      },
      __UNIQUE__Header_32: {
        fontFamily: "$helvetica",
        fontWeight: "$bold",
        fontSize: "$hoken-core-font-32",
        lineHeight: "115%",
        letterSpacing: "-0.005rem",
        textShadow: "0 0 1.25rem #000",
      },
      __UNIQUE__Header_24: {
        fontFamily: "$helvetica",
        fontSize: "$hoken-core-font-24",
        fontWeight: "$bold",
        lineHeight: "115%",
        letterSpacing: "-0.005rem",
      },
      __UNIQUE__Header_16: {
        fontFamily: "$helvetica",
        fontSize: "$hoken-core-font-16",
        fontWeight: "$bold",
        letterSpacing: "-0.005rem",
      },
      Header_H1: {
        fontFamily: "$helvetica",
        fontWeight: "$regular",
        fontSize: "$hoken-core-font-54",
        lineHeight: "$hoken-core-line-height-56",
        letterSpacing: "-0.005rem",
      },
      Header_H2: {
        fontFamily: "$helvetica",
        fontWeight: "$regular",
        fontSize: "$hoken-core-font-36",
        lineHeight: "$hoken-core-line-height-36",
        letterSpacing: "-0.005rem",
      },
      Header_H3: {
        ...header_h3,
      },
      Header_H4: {
        fontFamily: "$helvetica",
        fontWeight: "$regular",
        fontSize: "$hoken-core-font-20",
        lineHeight: "$hoken-core-line-height-24",
      },
      Header_H5: {
        ...header_h5,
      },
      Header_Eyebrow: {
        fontFamily: "$franklin",
        fontWeight: "$bold",
        fontSize: "$hoken-core-font-12",
        lineHeight: "$hoken-core-line-height-15",
        letterSpacing: "0.12rem",
        textTransform: "uppercase",
      },
      CardTitle_HotelName: {
        fontFamily: "$helvetica",
        fontWeight: "$regular",
        fontSize: "$hoken-core-font-18",
        lineHeight: "$hoken-core-line-height-19",
      },
      Paragraph_P1: {
        fontFamily: "$franklin",
        fontWeight: "$regular",
        fontSize: "$hoken-core-font-18",
        lineHeight: "$hoken-core-line-height-25",
      },
      Paragraph_P2: {
        fontFamily: "$franklin",
        fontWeight: "$regular",
        fontSize: "$hoken-core-font-15",
        lineHeight: "$hoken-core-line-height-25",
      },
      Paragraph_P3: {
        fontFamily: "$franklin",
        fontWeight: "$regular",
        fontSize: "$hoken-core-font-12",
        lineHeight: "$hoken-core-line-height-16",
      },
      Paragraph_P3_COMPENSATED: {
        fontFamily: "$franklin",
        fontWeight: "$medium",
        fontSize: "$hoken-core-font-12",
        lineHeight: "$hoken-core-line-height-16",
      },

      Paragraph_FinePrint: {
        fontFamily: "$franklin",
        fontWeight: "$regular",
        fontSize: "$hoken-core-font-10",
        lineHeight: "$hoken-core-line-height-12",
      },
    },
    color: {
      Default: {
        color: "$hoken-core-text-color",
      },
      Grayscale_70: {
        color: "$grey70",
      },
      Grayscale_75: {
        color: "$hoken-core-greyscale-75",
      },
      Grayscale_80: {
        color: "$hoken-core-greyscale-80",
      },
      Grayscale_90: {
        color: "$grey90",
      },
      White: {
        color: "$white",
      },
      Brand_primary: {
        color: "$primary",
      },
      Brand_dark_primary: {
        color: "$green",
      },
    },
    vertical_up: {
      "5": {
        padding: "$hoken-core-space-5 $0 $0 $0",
      },
    },
    vertical_down: {
      "5": {
        padding: "$0 $0 $hoken-core-space-5 $0",
      },
      "10": {
        padding: "$0 $0 $hoken-core-space-10 $0",
      },
    },
    vertical_both: {},
    horizontal_left: {},
    horizontal_right: {},
    horizontal_both: {
      "8": {
        padding: "$0 $hoken-core-space-8",
      },
    },
    align: {
      center: {
        textAlign: "center",
      },
      left: {
        textAlign: "left",
      },
      right: {
        textAlign: "right",
      },
    },
    weight: {
      bold: {
        fontWeight: "$bold",
      },
    },
  },
});
